@import 'components/global.scss';

.error-message,
.warning-message {

  display: block;
  color: white;
  font-size: 0.85em;
  font-weight: 500;
  margin-bottom: $scale2;
  padding: 0.6em $scale;

}

.error-message {

  background: darken($c-red, 3%);

}

.warning-message {

  background: $c-orange;

}
