@import "components/global.scss";

nav.sidebar,
nav.mobile-slideout {
  position: fixed;
  width: $scale12 + $scale5;
  height: 100%;
  z-index: 999;
  padding: $scale;
  background-color: #ffffff;
transition: .3s;
  a {
    display: block;
    text-decoration: none;
    color: #656565;
    font-size: 1em;
    padding: $scale $scale1;

    &.active {
      color: white;
      border-radius: $radius;
      background-color: $c-blue;
    }
  }

  @media (min-width: $mobile) {
    height: 100%;
  }

  @media (min-width: $mobile) {
    a {
      width: 100% !important;
    }
  }
}

nav.sidebar .nav-links {
  a {
    text-decoration: none;
    border: 2px solid #1595c5;
    border-radius: 11px;
    padding: 10px 10px;
    margin-bottom: 15px;
    font-weight: 600;
  }
}

nav.mobile-fixed {
  // moves navigation to bottom
  // of viewport like iOS app
  @media (max-width: $mobile) {
    .logo {
      display: none;
    }

    .nav-links {
      position: relative;
      top: auto;
    }

    a {
      &.active {
        border-top: 3px solid $c-primary !important;
        background-color: lighten($c-dark, 5%);
      }
    }
  }

  .btn-togglenav {
    display: none;
  }

  a {
    text-decoration: none;
  }
}

nav.mobile-slideout {
  @media (max-width: $mobile) {
    position: fixed;
    width: $scale11;
    left: -$scale11;
    height: 100%;
    background: $c-dark;
    transition: all 0.2s ease-in-out;

    .btn-togglenav {
      display: block;
      position: absolute !important;
      top: 2.2em;
      left: 100%;
      width: 2em;
      cursor: pointer;
      height: 2em;
      margin-left: $scale;

      @media (min-width: $mobile) {
        display: none !important;
      }
    }

    &.open {
      left: 0;

      + main {
        opacity: 0.05;
      }
    }

    .nav-links {
      position: relative;
      top: auto;
      transform: none;
    }

    a {
      display: block;
      float: none;
      color: white;
      margin-bottom: 0.75em;
    }
  }
}

nav.sidebar .nav-links.nested,
nav.mobile-slideout .nav-links.nested {
  ul a {
    color: #c2cbd9;

    &:hover {
      color: $c-primary;
    }
  }
}

.nav-links.nested {
  a {
    display: block;

    &:hover {
      color: $c-primary;
    }
  }

  > a {
    font-weight: 600;
  }

  // sub navs housed in ul
  ul {
    font-size: 0.9em;
    margin: 0 0 $scale2 $scale;

    a {
      margin-bottom: 0.8em;
    }
  }
}
