@import "./global/color";
@import "./global/scale";
@import "./global/utilities";

select {
  -webkit-appearance: none;
  -moz-appearance: none;

  &:focus {
    outline: none;
  }
}

.card2 {
  background-color: #e8f5f9;
}

nav.sidebar .nav-links .accountM__Sublist {
  a {
    background-color: #e3e3e3;
    color: #757575;
    border-color: #e3e3e3;
  }

  li.active {
    a {
      background-color: #d1eaf4;
      color: #757575;
      border-color: #d1eaf4;
    }
  }
}

nav.sidebar .nav-links {
  .account__M {
    background-image: url(../images/ang-down-b.svg);
    background-position: 94%;
    background-size: 18px;
    padding-right: 30px;
  }
}

.account__Menu {
  .accountM__Sublist {
    display: none;
  }

  &.open {
    .account__M {
      background-image: url(../images/ang-down-w.svg) !important;
      background-color: #1595c5;
      border-color: #1595c5;
      color: #ffffff;
    }

    .accountM__Sublist {
      display: block;
    }
  }
}

.cnfAccount-wrp {
  .card table.styled {
    border-spacing: 0;
    margin-bottom: 0;
  }
}

.cnfAccountList {
  .accountProfile {
    display: none;
  }

  .card {
    overflow: unset !important;
  }



  &.open {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-pack: justify;
    justify-content: space-between;

    .accountTable {
      width: 49%;
    }

    .accountProfile {
      width: 49%;
      padding-bottom: 12px;
      display: block;
    }

    div[role=row] div:nth-child(2),
    div[role=row] div:nth-child(4),
    div[role=row] div:nth-child(5) {
      display: none !important
    }
  }
}

.accountProfile {
  &.card:last-of-type {
    margin-bottom: 1.5625em;
  }

  h2 {
    color: #1997c6;
    display: block;
    font-size: 1.5625em;
    font-weight: 600;
    margin: 0;
    margin-bottom: 10px;
  }
}

.accountProfile {
  .tabView {
    .card {
      padding: 8px 0px;
      background: #d0eaf300;
      margin: 0;
      padding-bottom: 0;
      overflow: unset;
    }

    button {
      font-size: 9px;
      background-color: transparent;
      border: 1px solid #1494c5;
      border-radius: 20px;
      color: black;
      margin: 3px;
      padding: 5px 10px;

    }

    .active {
      background-color: #1494c5;
      color: white;
    }
  }
}

.closeBtn {
  float: right;

  svg {
    color: #1595c5;
  }
}

.headSearch {
  margin-bottom: 20px;

  h2 {
    color: #ffffff;
    font-size: 1.5625em;
  }
}

.searchWrp {
  -ms-flex-pack: justify;
  justify-content: space-between;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;

  .search {
    height: 42px;
    width: 500px;
    max-width: 100%;

    .textbox {
      height: 100%;
    }
  }
}

.accountFilter {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 40px;

  li {
    padding-right: 20px;
    text-align: center;

    span {
      color: #1997c6;
      display: block;
      font-size: 1.2em;
      font-weight: 600;
      margin-bottom: 10px;
    }
  }
}

.select__type__0 {
  padding: 10px 26px;
  min-width: 112px;
  border-radius: 15px;
  background-image: url(../images/ang-down-b.svg);
  background-position: 88% center;
  font-size: 13px;
  background-size: 15px;
  box-shadow: -3px 7px 20px 0px rgba(0, 0, 0, 0.15);
}

.select__type__I {
  background-image: url(../images/ang-down-br.svg);
  background-position: 100% center;
  font-size: 13px;
  background-size: 15px;
  padding-right: 22px;
  background-color: #fff0;
  color: #717171;
  font-weight: 600;
  line-height: 35px;
  padding: 1px 30px;
}

.select__type__II {
  padding: 10px 22px;
  min-width: 110px;
  border-radius: 15px;
  background-image: url(/static/media/ang-down-b.a88eaefc.svg);
  background-position: 88% center;
  font-size: 13px;
  background-size: 15px;
  border: 1px solid #1595c5;
  border-radius: 13px;
  background-color: #fff0;
}

.radiolist {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  position: absolute;
  top: 39px;
  right: 18px;
  margin: auto;

  li {
    &+li {
      margin-left: 8px;
    }

    &:hover {
      color: #c7b087;
    }
  }

  label {
    width: 100%;
    position: relative;
    cursor: pointer;
    font-size: 13px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: #404040;
    margin-bottom: 0;
    text-transform: inherit;
    padding-left: 15px;

    &:hover {
      /*color: #00B67A;*/
    }

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }
  }

  .checkmark {
    height: 11px;
    width: 11px;
    border-radius: 50%;
    left: 0;
    top: 0;
    position: absolute;
    border: 1px solid #707070;
    background-color: #ffffff;
    right: auto;
    bottom: 0;
    margin: auto;
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  label input:checked~.checkmark:after {
    display: block;
  }

  .checkmark:after {
    left: 0px;
    top: 0px;
    width: 75%;
    height: 75%;
    bottom: 0;
    margin: auto;
    right: 0;
    border-radius: 50%;
    border: 1px solid #ffffff;
    background: #787979;
  }
}

.formGroup {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: justify;
  justify-content: space-between;

  .ipBox {
    width: 49%;
  }
}

.ipBox {
  margin-bottom: 15px;
  position: relative;

  input {
    border: 1px solid #707070;
    padding: 11px 10px;
    width: 100%;
    border-radius: 14px;
    background: none;
  }
}

.rawData-wrp {
  .searchWrp {
    width: 100%;
    margin-bottom: 30px;
  }
}

.modal .addUserModal.modal-content {
  max-width: 580px;
}

.aadd__user_form {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: center;

  .ipBox {
    width: calc(100% / 3 - 15px);
    margin: 13px 7px;

    input {
      background: #efefef;
      border: 1px solid #efefef;
      box-shadow: -2px 5px 13px #efefefb3;

      &::-webkit-input-placeholder {
        color: #707070;
        text-align: center;
        font-weight: 600;
      }

      &::-moz-placeholder {
        color: #707070;
        text-align: center;
        font-weight: 600;
      }

      &:-ms-input-placeholder {
        color: #707070;
        text-align: center;
        font-weight: 600;
      }

      &:-moz-placeholder {
        color: #707070;
        text-align: center;
        font-weight: 600;
      }
    }
  }

}

.button_tab {
  margin-top: 30px;
}

.inviteBtn {
  background-color: #1595c5;
  color: #fff;
  width: 168px;
  float: none !important;
  display: block;
  margin: auto;
  margin-top: 20px;
}

.mobMenu__ic {
  display: none;
}

.dashboardFilter {
  width: 100%;
  display: inline-block;

  h1 {
    color: black;
    margin-bottom: 20px;
  }

  .card {
    text-align: center;
    padding: 0;
    background: #a5a5a500;
    border-radius: 0;
    padding-bottom: 18px;
  }

  .selectBox {
    width: 90%;
    margin: auto;
    background-color: #ffffff;
    border-radius: 15px;
    padding: 15px 10px;
    box-shadow: 0px 2px 13px 0px rgba(0, 0, 0, 0.15);

    h1 {
      margin: 0;
    }
  }
}

.report-wrp {
  margin-bottom: 50px;

  .card {
    background: #f3f9fc;
  }

  .select__type__II {
    padding: 10px 40px 10px 22px;
    background-color: #fff;
    background-position: 94% center;
    box-shadow: -3px 7px 20px 0px rgba(0, 0, 0, 0.15);
    margin: 15px 12px;
  }

  span {
    font-size: 14px;
    color: #777777;
  }
}

@media (max-width: 1600px) {}

@media (max-width: 1400px) {
  .select__type__0 {
    min-width: 100px;
  }

  .accountFilter li span {
    font-size: 14px;
    margin-bottom: 10px;
  }

  .accountFilter li {
    padding-right: 12px;
  }

  .headSearch h2 {
    font-size: 16px;
  }

  .searchWrp .search {
    height: 35px;
  }

  .btn {
    font-size: 14px;
    height: 35px;
  }

  .accountProfile h2 {
    font-size: 18px;
  }

  .ipBox input {
    padding: 8px 10px;
  }

  .radiolist {
    top: 33px;
  }

  nav.sidebar .nav-links a {
    padding: 7px 10px;
    margin-bottom: 10px;
    font-size: 14px;
  }
}

@media (max-width: 1199px) {
  .cnfAccountList.open {
    display: block;
  }

  .cnfAccountList.open .accountTable {
    width: 100%;
  }

  .cnfAccountList.open .accountProfile {
    width: 100%;
  }

  .searchWrp .search {
    width: 360px;
  }

  .card table.styled td {
    padding: 5px 0;
  }
}

@media (max-width: 991px) {
  .searchWrp .search {
    width: 225px;
  }
}

@media (max-width: 767px) {

  nav.sidebar,
  nav.mobile-slideout {
    left: -17.60367em;

    a {
      width: 100% !important;
    }

    &.active {
      left: 0;
      transition: .3s;
    }
  }

  main.with-sidebar {
    padding-left: 1.5625em;
  }

  header {
    text-align: center;
  }

  .mobMenu__ic {
    display: block;
    float: left;
  }

  .accountFilter li {
    margin-bottom: 12px;
  }

  .accountProfile .tabView button {
    padding: 8px 10px;
  }

  .select__type__0 {
    min-width: auto;
    padding: 6px 24px 6px 10px;
    font-size: 12px;
  }

  .accountFilter {
    margin-bottom: 20px;
  }

  .select__type__II {
    padding: 5px 22px 5px 5px;
    min-width: auto;
  }

  .report-wrp .select__type__II {
    padding: 8px 28px 8px 10px;
    margin: 6px 6px;
  }

  .dashboardFilter h1 {
    margin-bottom: 10px;
  }
}

@media (max-width: 575px) {
  .btn {
    font-size: 11px;
    height: 35px;
  }

  .searchWrp .search {
    width: 185px;
  }

  .formGroup {
    display: block;
  }

  .formGroup .ipBox {
    width: 100%;
  }

  .aadd__user_form .ipBox {
    width: calc(100% / 2 - 15px);
  }
}

@media (max-width: 400px) {
  .searchWrp .search {
    width: 100%;
    margin-bottom: 5px;
  }
}

.llll {
  & div {
    z-index: 9999;
    border: none;
  }
}

.custom-select {
  width: 200px;
}

.accountTable {
  .rdt_TableHeader {
    display: none !important;
  }
}

.tabs .active:before {
  background-color: transparent !important;
}

.addbtn {
  border-radius: 2px !important;
  background-color: #1494c5 !important;
  color: white !important;

}

.cnfAccount-wrp .card table.styled {
  font-size: 13px !important;
}

.ipSelect {
  border: 1px solid black;
  padding: 7px 5px;
  width: 100%;
  border-radius: 20px;
}

.access-form {
  margin: 10px;

}

.access-header {
  margin-bottom: 5px;
  border-bottom: 2px solid #1494c5;
}

.access-form span {
  font-size: 10px;
}

.dots div {
  width: 16.6%;
  text-align: center;
  margin-bottom: 10px;
}

.dots {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
}

@media (min-width: 1200px) {
  .dashboardFilter .grid {
    display: flex
  }
}

.btn {
  min-height: 46px;
}

button:disabled {
  cursor: not-allowed !important;
  pointer-events: all !important;
}

.logo-container img {
  max-height: 163%
}

.login-email {
  text-align: center;
  margin-bottom: 30px;
}

.login-email span {


  border-radius: 16px;
  align-items: center;
  background: #fff;
  border: 1px solid #dadce0;
  color: #3c4043;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: inline-flex;
  font-family: 'Google Sans', arial, sans-serif;
  font-size: 17px;
  font-weight: 500;
  letter-spacing: .25px;
  max-width: 100%;
  position: relative;
  height: auto;
  width: auto;
  padding: 5px 10px 5px 10px;
}

.login-email-close {
  margin-right: 5px;
  cursor: pointer;
  padding: 5px 3px 2px 2px;
}

.login-email-title {
  text-align: center;  
  color: #73B0F4;
  font-size: 1.8625em;
  font-weight: 600;
  margin-top: 10px;
}

.login-email span:hover {

  background-color: #f1f1f1;
}

.login-email-close:hover {
  background-color: #ffc3d2;
}