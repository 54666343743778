@import 'components/global.scss';

.logo {

  display: block;
  width: $scale10;
  height: $scale4;
  margin: 0 auto $scale2;
  outline: none;

}
