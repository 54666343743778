@import 'components/global.scss';

footer {

  color: white;
  overflow: hidden;
  background-color: darken($c-darkpurple, 26%);

  .copyright {

    margin-bottom: $scale;

  }

  span, address {

    float: left;
    clear: both;
    opacity: 0.7;
    font-style: normal;
    font-size: 0.8em;

  }

  address {

    display: block;
    opacity: 0.5;
    margin-top: 0.5em;

  }

  nav {

    float: left;
    clear: both;
    margin-bottom: $scale;

    a {

      color: white;
      font-size: 0.8em;
      opacity: 0.7;
      text-decoration: none;
      margin-right: $scale1;
      text-transform: uppercase;
      transition: all 0.3s ease-in-out;

      &:hover {

        opacity: 1;
        text-decoration: underline;

      }
    }
  }
}
