@import 'components/global.scss';

.switch {

  position: relative;
  display: block;
  width: 100%;
  overflow: hidden;
  margin-bottom: $scale1;

  .track {

    position: relative;
    float: left;
    width: 3.2em;
    height: 1.75em;
    cursor: pointer;
    overflow: hidden;
    border-radius: 2em;
    background: darken($c-bg, 30%);
    transition: all 0.3s ease-out;

  }

  .handle {

    position: absolute;
    top: 50%;
    left: 0.2em;
    color: white;
    width: 1.75em;
    height: 1.75em;
    font-size: 0.85em;
    font-weight: 600;
    line-height: 2.1em;
    text-align: center;
    text-transform: uppercase;
    pointer-events: none;
    background-color: white;
    transition: all 0.1s ease-out;
    border-radius: 100%;
    transform: translateY(-50%);
    -ms-transform: translateY(-50%);

  }

  label {

    position: absolute;
    left: 5em;
    top: 50%;
    text-transform: none;
    transform: translateY(-50%);
    -ms-transform: translateY(-50%);

  }

  &.on {

    .track {

      background-color: $c-green;

    }

    .handle {

      left: 1.75em;

    }
  }
}

.switch.with-labels {

  label {

    left: 7.5em;

  }

  .track {

    width: 5em;
    height: 2em;
    border-radius: 0;
    background: white;
    border: 1px solid $c-border;

  }

  .handle {

    height: auto;
    width: 3em;
    left: 0.1em;
    border-radius: 0;
    background-color: $c-primary;
  }

  &.on {

    .handle {

      left: 2.6em;
      background-color: $c-green;

    }
  }
}
