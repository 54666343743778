@import 'components/global.scss';

.setup {

  padding: $scale2;

  .progress-steps {

    margin: $scale4 0 $scale2;

    li, a {

      color: white;

    }
  }

  .welcome-message {

    text-align: center;
    width: 100%;

    h1 {

      color: white;
      font-size: $scale4;
      transition: 3s all cubic-bezier(.1, -.21, .19, 1.44);

      &.dim {

        opacity: 0;

      }
    }

    p {

      color: white;
      font-size: $scale1;
      margin-top: $scale3;

    }

    .btn {

      display: inline-block;
      font-size: $scale2;
      margin-top: $scale;
      transition: 4s all cubic-bezier(.1, -.21, .19, 1.44);

      &.dim {

        opacity: 0;

      }
    }

    @media (min-width: $mobile){

      h1 {

        font-size: $scale6;

      }
    }
  }

  > .card,
  > .message {

    margin: 0 auto;

  }

  @media (min-width: $mobile){

    .card {

      max-width: $scale16;

    }

    .message {

      max-width: $scale17;

    }
  }
}
