@import 'components/global.scss';

.label + .progress-bar {

  margin: 0.25em 0 $scale;

}

.progress-bar {

  width: 100%;
  height: 0.75em;
  background-color: #EBEFF4;

  &.red .progress-fill {

    background-color: $c-red;

  }

  &.orange .progress-fill {

    background-color: $c-orange;

  }

  &.green .progress-fill {

    background-color: $c-green;

  }

  .progress-fill {

    height: 100%;
    transition: all 0.5s;
    background-color: $c-green;

  }
}
