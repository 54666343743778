@import 'components/global.scss';

.chartWrapper {

  position: relative;
  min-height: 22em;

  .blankslate {

    position: absolute;
    top: 50%;
    left: 50%;
    text-align: center;
    font-weight: 600;
    text-align: center;
    transform: translate(-50%, -50%);

    .ico {

      float: none;
      margin: -$scale2 auto $scale/2;

    }
  }
}

.chart {

  height: 13.5em;
  margin-top: 50px;
  min-height: 13.5em;
  cursor: pointer;

  canvas {

    max-height: 15em;

  }
}

.sparkline {

  position: relative;
  height: 2em;
  width: 12em;

}

.legend {

  overflow: hidden;
  margin-bottom: $scale2;

  li {

    float: left;
    line-height: 10px;
    font-size: 0.85em;
    margin: 0 $scale $scale/2 0;

    span {

      position: relative;
      display: inline-block;
      width: 12px;
      height: 12px;
      top: 2px;
      background-color: #666;
      margin-right: $scale/2;

    }
  }
}

.multiSelect {
  background: white;
  padding: 4px 5px !important;
}
.multiSelect:focus {
  box-shadow: 0 0 0 2px #2684FF !important;
}
.multiSelect:focus-within {
  box-shadow: 0 0 0 2px #2684FF !important;
}
.report-wrp span {
  color: black !important;
}