@import 'components/global.scss';

fieldset {

  clear: both;
  padding: 0;
  margin: 0 0 $scale/2 0;
  border: none;

  .checkbox,
  .radio {

    margin-bottom: 0.25em;

  }

  label {

    text-transform: none;

  }

  &.error {

    padding: 0 $scale 0 $scale;
    border: 1px solid $c-red;

    legend {

      color: $c-red;
      padding: 0 0.5em;

    }
  }

  .error-message {

    clear: both;
    margin: 0 $scale1*-1;

  }
}
