@import 'components/global.scss';

.app {

  padding: $scale2;

}
body {
  height: 100%;
  background-color: #1494c5;
}
#root {
  height: 100%;
  background: #1494c5;
}
body.color-bg #root{
  background-color: #1494c5;
  background-image: url(/client/src/components/backgroundimage/light.png);
  background-size: 100% 100%;
}