@import 'components/global.scss';

.plans {

  clear: both;
  padding-top: 0;
  padding-bottom: 0;
  margin-bottom: $scale4;

  h1 {

    color: white;
    font-size: $scale2;
    margin-bottom: $scale1;

  }

  .plan {

    position: relative;
    overflow: visible;
    text-align: left;

    .name {

      float: left;
      color: $c-primary;
      font-weight: 600;
      font-size: $scale1;
      text-transform: uppercase;

    }

    .price {

      float: right;
      font-weight: 600;
      margin-top: -.5em;

    }

    .value {

      font-size: $scale3;
      font-weight: 800;
      color: $c-purple;

    }

    .period {

      color: $c-text;
      text-align: right;
      margin-top: -.25em;
      font-size: $scale-1;

    }

    .save {

      position: absolute;
      top: -$scale;
      right: -$scale;
      width: $scale6;
      height: $scale6;
      padding-top: 1em;
      line-height: 1em;
      color: white;
      font-weight: 600;
      border-radius: 100%;
      text-transform: uppercase;
      background-color: $c-orange;

    }

    .checklist {

      float: left;
      clear: both;
      margin-top: $scale;
      margin-bottom: $scale;

      li {

        margin-bottom: $scale/2;

      }
    }

    .btn {

      display: block;
      clear: both;

    }
  }

  @media (min-width: $mobile){

    .plan {

      width: 30%;
      display: inline-block;
      margin-right: 1%;

      &:last-child {

        margin-right: 0;

      }
    }
  }
}
