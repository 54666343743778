@import 'components/global.scss';

.modal {

  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999999;
  background: black;
  background: rgba(0, 0, 0, 0.8);
  transition: all 0.3s ease-in-out;

  .container-body {
    display: block;
  	margin: auto;
  	width: 75%;
  }

  .checkbox {
    float: none !important;
    margin-right: 0px !important;
  }

  .checkbox input {
    float: none !important;
    width: 1.5em;
    height: 1.5em;
    margin-right: 0.5em;
  }

  .checkbox input:checked {
    background-color: #1A8DBB !important;
  }

  .block div {
    padding: 5px;
    border-radius: 15px;
    color: #404040;
  }

  .block label {
    text-shadow: 0.2px 0.2px 0.2px #000000;
    display: flex;
    margin: 0 auto;
    justify-content: center;
    color: rgba(0, 0, 0, 0.8);
  }

  .block label p {
    padding-bottom: 10px;
  }

  .datePicker {
    display: block;
  	margin: auto;
  	width: 75%;
  }

  .hidden {
    display: none;
  }
  
  .btn-success {
    background-color: #1A8DBB;
    border-radius: 15px;
  }
  .btn-success:hover {
    background-color: #1A8DBB;
    border-radius: 15px;
  }
  .btn-warning {
    background-color: #E62020;
    border-radius: 15px;
  }
  .btn-warning:hover {
    background-color: #E62020;
    border-radius: 15px;
  }

  .buttonsCsv {
    margin-top: 20px;
  }
  .smDownLink {
    margin-top: 20px;
  }
  .smDownNote {
    margin-top: 20px;
    font-size: small;
  }
  .modalNote {
    margin-top:10px;
    font-size: small;
    a {
      color: red;
    }
    ul {
      list-style: circle;
      margin-left: 10px;
      ol {
        margin-left: 20px;
      }
    }
  }
  .emailInput {
    border: 1px solid gray;
    border-radius: 5px;
    padding: 8px;
    margin-top: 10px;
    min-width: 300px;
  }

  header {

    margin-bottom: $scale;
    text-align: center !important;
    background-color: #148DBB !important;
  }
  .table-container{
    min-height: 400px;
    margin-bottom: 10px;
  }

  &.show {

    display: block;
    opacity: 0;

    .modal-content {

      top: -50%;

    }
  }

  &.hide {

    display: none;
    opacity: 0;

    .modal-content {

      top: 0%;

    }
  }

  &.animateIn {

    opacity: 1;

    .modal-content {

      top: 50%;

    }
    .kit-modal-content {

      top: 50%;

    }
  }

  &.animateOut {

    opacity: 0;

    .modal-content {

      top: 0%;

    }
  }

  .modal-content {

    position: absolute;
    left: 50%;
    width: 94%;
    top: 50%;
    text-align: left;
    max-height: $scale17;
    //overflow-y: scroll;
    background-color: white;
    transform: translate(-51%, -50%);
    -ms-transform: translate(-50%, -50%);
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
    transition: all 0.2s ease-out;

    header {
        & h1 {
          color: white;
        }
        .closeBtnModal {
          float: right;
          cursor: pointer;
          & svg {
            color: white;
            float: right;
          }
        }
    }

  }

  .kit-modal-content {

    position: absolute;
    left: 50%;
    width: 518px;
    top:50%;
    text-align: left;
    max-height: $scale17;
    //overflow-y: scroll;
    background-color: white;
    transform: translate(-51%, -50%);
    -ms-transform: translate(-50%, -50%);
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
    transition: all 0.2s ease-out;
    overflow: hidden;

    header {
        & h1 {
          color: white;
        }
        .closeBtnModal {
          float: right;
          cursor: pointer;
          & svg {
            color: white;
            float: right;
          }
        }
    }
    .superDtableWrap{
      .rdt_TableHeader{
        background-color: transparent !important;
        padding: 0;
        margin: 0
      }
    }

  }

  .custom {

    font-weight: 700;

  }

  @media (max-width: 299px){

    display: none;

    .btn, .btn-loader {

      width: 100%;

      &:last-child {

        margin-top: $scale/2;

      }
    }
  }

  @media (min-width: 300px){

    .btn, .btn-loader {

      float: left;
      width: 48%;

      &:last-child {

        float: right;

      }
    }
  }

  @media (min-width: 500px){

    .modal-content {

      max-width: $scale15;

    }
    .kit-modal-content {

      max-width: 37.42171em;

    }

  }
}
