@import 'components/global.scss';

label,
legend {

  display: block;
  position: relative;
  color: lighten($c-text, 5%);
  font-size: 0.85em;
  margin-bottom: 0.65em;
  text-transform: uppercase;

  &.required:after {

    content: "*";
    color: $c-red;
    margin-left: 0.35em;
    font-size: $scale;
    font-weight: 500;

  }
}
