@import 'components/global.scss';

.grid {

  .card {

    margin-bottom: 0;

  }

  > * {

    width: 100%;
    float: left;
    margin-bottom: $scale !important;

  }

  &+.card {

    clear: both;

  }
}

.two-col {

  > * {

    @media (min-width: 600px){

      width: 49%;

      &:nth-of-type(2n+1){

        margin-right: 2%;

      }
    }
  }
}

.three-col {

  > * {

    // switch to three column layout
    @media (min-width: 850px){

      width: 32%;

      // reset from two-col
      &:nth-of-type(2n+1){

        margin-right: 0;

      }

      &:nth-of-type(3n+1),
      &:nth-of-type(3n+2){

        margin-right: 2%;

      }
    }
  }
}

.four-col {

  > * {

    // switch to four column layout
    @media(min-width: 1100px){

      width: 24%;

      // reset from 3 col
      &:nth-of-type(3n+1),
      &:nth-of-type(3n+2){

        margin-right: 0;

      }

      &:nth-of-type(4n+1),
      &:nth-of-type(4n+2),
      &:nth-of-type(4n+3){

        margin-right: 1.33%;

      }
    }
  }
}

.five-col {

  > * {

    // switch to five column layout
    @media(min-width: 1400px){

      width: 19.2%;

      // reset
      &:nth-of-type(4n+1),
      &:nth-of-type(4n+2),
      &:nth-of-type(4n+3){

        margin-right: 0%;

      }

      &:nth-of-type(5n+1),
      &:nth-of-type(5n+2),
      &:nth-of-type(5n+3),
      &:nth-of-type(5n+4){

        margin-right: 1%;

      }
    }
  }
}
