/* .search_box {
    
} */
.search_box input {
    border: 1px solid #d4d4d4;
    padding: 10px;
    width: 80%;
    height: 50px;
    border-radius: 20px;
    font-size: 14px;
}
.superDataTable header {
    display: none;
}
.superDataTable .rdt_TableCol {
    font-size: 15px;
    font-weight: 600;
}
.superDataTable .superDtableWrap {
    border-radius: 20px;
}
.superDataTable .superDtableWrap .rdt_Table {
    padding: 10px;;
}
.sUserAdd
{
    border-radius: 15px;
    color: #1595c5;
    cursor: pointer;
    font-size: 1em;
    font-weight: 600;
    text-align: center;
    text-decoration: none;
    padding: 0em 1.44141em;
    transition: all 0.4s ease-in-out;
    background-color: #ffffff;
    height: 50px; 
    
}
.sUserAdd:hover {
    background-color: #1595c5;
    color: white;
    transition: all 0.4s ease-in-out;
}

.dashboard-container {
    display: flex;
    padding-bottom: 20px;
}
.dashboard-1,
.dashboard-2 {
    flex: 1;
}
.dashboard-2 {
    padding-top: 100px;
}

.dashboardPreview {
    display: flex;
    flex-wrap: wrap; 
    list-style-type: none;
    border: 1px solid;
    padding: 20px 20px 10px;
    border-radius: 15px;
    justify-content: space-between;
}
.dashboardPreview li {
    width: 49%;
    /* Adjust the width as needed */
    margin-bottom: 10px;
}

.menu-item {
    display: flex;
    align-items: center;
    height: 100%;
    font-size: 15px
}
.menu-item img {
  margin-right: 8px;  
  max-width: 28px;
  height: auto;
}
.dashboardItems {
    max-width: 600px;
    /* display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(2, 1fr); */
    /* display: flex;
    flex-wrap: wrap; */
    list-style-type: none;
    /* color: black; */
    /* grid-template-columns: 2fr 2fr; */
}

.dashboardItems li {
    /* Adjust the width as needed */
    width: 60%;
    margin-bottom: 10px;
}
.dashboard-button {
    font-size: 0.8em;
    border-radius: 15px;
    color: #1595c5;
    font-weight: 600;
    text-decoration: none;
    padding: 0 15px;
    transition: all 0.4s ease-in-out;
    background-color: #ffffff;
    height: 40px;
    /* margin-left: 6px; */
}
.dashboard-1 .dashboard-button {
    height: 42px;
}
.dragdrop-wrp .sUserAdd {
    margin: auto;
    width: 170px;
    display: block;
    margin-bottom: 35px;
    font-size: 20px;
    background-color: #00344f;
    color: white;
    border: none;
}
.dragdrop-wrp .sUserAdd:hover{
    background-color: green;
}
.dragdrop-wrp h5 {
    font-size: 20px;
    margin-bottom: 20px;
    font-weight: 600;
}
.dragdrop-wrp p {
    margin-bottom: 10px;
    font-size: 18px;
}
.mb-35 {
    margin-bottom: 35px;
}
/* .close-btn {
    background-color: 'lightgray';
    padding: '0';
    float: 'right';
    margin-left: '15px'
} */

.dragdrop-wrp {
    color: #ffffff;
    font-size: 1.5em;
    /* padding: 0 10%; */
}
.btn-loader.loading-blue {
    border-radius: 15px;
    color: #1595c5;
    cursor: pointer;
    font-size: 1em;
    font-weight: 600;
    text-align: center;
    text-decoration: none;
    padding: 0em 1.44141em;
    transition: all 0.4s ease-in-out;
    background-color: #ffffff;
    height: 50px; 
}
.btn-loader.loading-blue:hover {
    background-color: #1595c5;
    color: white;
    transition: all 0.4s ease-in-out;
    border: 1px solid #fff;
}
.loading-blue{
    color: #1595c5 !important;
    font-weight: 600 !important;
}
.loading-blue:hover{
    color: white !important
}
button.sUserAdd{
    border: 1px solid #fff
}
.tableHeadMain {
    width: 100%;
    display: inline-block;
    margin: 20px 0;
}
.tableHeadMain .tsecOne {
    width: 50%;
    float: left;
}
.tableHeadMain .tsecTwo {
    width: 50%;
    float: left;
    text-align: right;
}
.tableHeadMain .cntrBtn {
    margin: auto;
    display: block;
}
.tblAction 
{
    border: none;
    color: #1595c5;
    font-size: 16px; 
    cursor: pointer; 
    float: left;
    padding-right: 4px;
    padding-left: 4px;
}
.tblAction :hover {
    color: #88b9cc;
}
.disabled {
    color: red;
}
.centerLoadBtn {
    text-align: center;
    margin-top: 25px;
    padding-bottom: 20px;
}
.kitIdTable {
    background: white;
    border-radius: 20px;
}
.upKit {
    margin-left: 6px;
}
.filterItemsKit {
    padding-top: 30px;
    margin-bottom: 15px;
    padding: 10px;
}
@media (max-width: 768px) {
    .search_box input {
        width: 100%;
    }
}
.kitIdManagement-id {
    text-decoration: underline !important;
    cursor: pointer !important;
}
