@import 'components/global.scss';
body {
    height: 100%;
    background-color: #c4e4f1;
  }
  #root {
    height: 100%;
    background: #c4e4f1;
  }
  body.color-bg #root{
    background-color: #546DB7;
    background-image: url(/client/src/components/backgroundimage/light.png);
    background-size: 100% 100%;
  }