@import 'components/global.scss';

.external {

  margin-top: $scale2;

  .message {

    position: absolute;
    top: 50%;
    left: 50%;
    text-align: center;
    max-width: $scale15;
    color: white;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);

    h1 {

      font-size: $scale3;
      font-weight: 800;
      color: white;
      margin: 0.25em 0 0.5em;

      &.large {

        font-size: $scale8;
        margin-bottom: 0;

      }
    }
  }
}
