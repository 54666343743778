@import 'components/global.scss';

.auth {

  padding-top: 0;
  padding-bottom: $scale4;

  h1 {

    color: white;
    text-align: center;
    font-size: $scale2;
    margin-bottom: $scale1;

  }

  .card {

     margin: 0 auto;
    border-radius: 8px;    
  }

  .panel-row:nth-child(2) {

    padding-top: 0;
    padding-bottom: 0;

  }

  span {

    display: block;
    width: 100%;
    text-align: center;
    margin-top: $scale1;

  }

  @media (min-width: $mobile){

    .card {

      width: $scale15;
      margin: 0 auto;

    }
  }
}
