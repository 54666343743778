@import 'components/global.scss';

header {

  position: relative;
  width: auto;
  z-index: 3;
  background: #00344f;
  padding: $scale2;
  margin: $scale2*-1 $scale2*-1 $scale2 $scale2*-1;
  
  @media (max-width: $mobile){

    color: #00344f;
    text-align: center;
    background: $c-dark;
    border: none;

  }

  .hover-nav {

    position: absolute;
    top: 50%;
    right: $scale2;
    min-width: $scale10;
    text-align: right;
    color: white;
    transform: translateY(-50%);
    -ms-transform: translateY(-50%);

  }
  h1 {
    color: white;
  }
  .partnerDropdown {
    position: absolute;
    top: 25px;
    right: 25%;
    cursor: pointer;
    & select {
      padding: 10px 30px;
    border-radius: 15px;
    background-image: url(/static/media/ang-down-b.a88eaefc.svg);
    background-position: 94% center;
    font-size: 13px;
    background-size: 15px;
    border: 1px solid #1595c5;
    border-radius: 13px;
    background-color: white;
    }
  }
  .lang-btn{
    padding:10px 20px 14px 20px!important
  }
}
